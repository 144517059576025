<script>
import ModalContentDetails from '@/components/contents/ModalContentDetails'
export default {
  name: 'ContentCardWide',
  components: { ModalContentDetails },
  watch: {},
  data () {
    return {
      showModal: false,
      contentIdProp: null,
      dialog: false,
      showContent: true,
      actionOptions: ['delete'],
      loading: false,
      iconsMap: {
        course: 'mdi-laptop',
        video: 'mdi-play-circle-outline',
        article: 'mdi-text-box',
        podcast: 'mdi-microphone',
        slides: 'mdi-collage',
        book: 'mdi-book',
        event: 'mdi-calendar',
        questionnaire: 'mdi-list-status'
      }
    }
  },
  props: {
    content: {
      type: Object
    },
    originWindow: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getTitleIcon () {
      return this.iconsMap[this.content.contentType.alias]
    },
    getDefaultImageByContentType () {
      return `/assets/images/background-${this.content.contentType.alias}.png`
    },
    countSkills () {
      return this.content.skills.length
    }
  },
  methods: {
    showContentLink () {
      window.open(this.content.link, '_blank')
      this.$store.dispatch('attemptSaveAccess', this.content.id)
    },
    hideModal () {
      this.$emit('hideModal')
    },
    emitRemove () {
      this.$emit('remove', this.content.id)
    },
    executeAction (action) {
      switch (action) {
        case 'delete':
          this.emitRemove()
          break
      }
    },
    showDetails () {
      const params = { contentId: this.content.id }
      // this.$router.push({ name: `${this.$route.name}.content.details`, params })
      this.contentIdProp = params
      this.showModal = true
    }
  }
}
</script>
<template>
  <v-card v-if="loading" class="content-card-wide">
    <v-card-title><v-btn text loading></v-btn>{{$t('global:loading')}}</v-card-title>
  </v-card>
  <v-card v-else elevation="8" class="content-card-wide">
    <v-img
      :src="content.coverImageURL || getDefaultImageByContentType"
      class="content-card--header-image"
    >
      <div class="content-card--header-upper">
        <v-icon color="#FFF" medium v-if="content.isPrivate" class="lock-icon">mdi-lock</v-icon>
      </div>
      <div class="content-card--header-bottom">
        <v-chip-group class="insights" v-if="countSkills > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="#FFF"
                text-color="#1200D3"
                v-on="on"
                v-bind="attrs"
              >
                <v-icon left>
                  mdi-stairs-box
                </v-icon>
                {{ countSkills }}
              </v-chip>
            </template>
            <span>{{$tc('global:number.skills', countSkills, [countSkills])}}</span>
          </v-tooltip>
        </v-chip-group>
      </div>
    </v-img>
    <div class="content-card--card-bottom">
      <div class="content-card--card-bottom-info-wrapper">
        <div class="content-card--card-bottom-info">
          <p class="content-title content-type">
            <v-icon class="mr-2">{{ getTitleIcon }}</v-icon>
            <span>{{ $t(`content.type:${content.contentType.alias}`) }}</span>
          </p>
          <v-card-subtitle class="text-greyed text-left">
            {{ $t(`skill.modal.levels:${content.level}`) }} &bull; {{ totalDurationByMs(content.duration) }}
          </v-card-subtitle>
        </div>
        <div class="content-card--card-bottom-title">
          <h5 class="h8">{{ content.title }}</h5>
        </div>
        <v-card-actions>
          <v-btn
            small
            outlined
            color="#1200D3"
            class="ml-n4 see-more-btn"
            @click="showDetails"
          >
            {{ $t('global:see.more') }}
          </v-btn>
          <v-btn
            v-if="false"
            small
            disabled
            outlined
            color="#1200D3"
            class="ml-n4 lato bolder"
          >
            {{ $t('global:complete') }}
          </v-btn>
          <v-btn
            v-if="false"
            small
            disabled
            outlined
            color="#1200D3"
            class="ml-n4 lato bolder"
          >
            {{ $t('global:add') }}
          </v-btn>
        </v-card-actions>
      </div>
      <div class="content-card--card-secondary-action">
        <v-menu bottom left absolute transition="slide-x-transition" min-width="200" v-if="editable">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon color="#1200D3" v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(action, i) in actionOptions"
              :key="i"
              @click="executeAction(action)"
            >
              <v-list-item-title>{{ $t(`content.wide.card.action.options:${action}`) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <modal-content-details v-if="showModal" :contentIdProp="contentIdProp" @close="showModal = false"/>
  </v-card>
</template>
<style lang="scss">
.content-card-wide {
  display: flex;
  width: 100%;
  height: 220px;
  background: $neutral-white;
  .content-card--header-image {
    height: 220px;
    max-width: 160px;
    &.v-image.v-responsive {
      border-radius: 4px 0 0 4px;
    }
    .v-responsive__content {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      background-color: rgba(0, 0, 0, .2);
      .content-card--header-upper {
        display: block;
        height: 40px;
        width: 100%;
        padding: 8px;
        text-align: left;
      }
      .content-card--header-middle {
        padding: 0 10px;
        height: 50px;
        .content-title {
          width: 100%;
          color: $neutral-white;
          font-family: $lato;
          font-weight: bold;
          font-size: $font-size-normal;
          text-align: left;
        }
      }
      .content-card--header-bottom {
        display: flex;
        flex-direction: column-reverse;
        padding: 8px;
      }
    }
  }
  &.v-card:last-child {
    .content-card--card-bottom {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
  }
  .content-card--card-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 8px;
    height: 100%;
    width: 100%;
    border: 1px solid $primary-medium;
    .content-title {
      margin: 0;
    }
    .v-card__subtitle {
      padding: 0 8px;
    }
    .v-card__actions {
      display: flex;
      padding: 8px;
      .v-btn {
        margin-right: 20px;
      }
    }
    .content-card--card-bottom-info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      .content-card--card-bottom-info {
        display: flex;
        .content-title {
          @extend .bolder;
          display: inline;
          vertical-align: middle;
          color: $primary-medium;
          text-transform: uppercase;
          margin-right: 20px;
          .v-icon {
            color: $primary-medium;
          }
        }
      }
    }
    .content-card--card-bottom-title h3 {
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 75px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 1.6;
      display: -webkit-inline-box;
    }
  }
  .title-container {
    color: white;
  }
  .content-level-info {
    font-family: $lato;
    font-size: $font-size-normal;
    color: $neutral-medium;
  }
  .content-title-info {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .see-more-btn {
    font-family: $lato;
    font-size: $font-size-small;
    font-weight: bold;
  }
  .background-secondary {
    background-color: $secondary-medium;
  }
  .v-slide-group__prev--disabled {
    display: none;
  }
  .content-type {
    text-transform: capitalize;
  }
}
@media only screen and (max-width: 768px) {
  .content-card-wide {
    flex-direction: column;
    height: inherit;
    .content-card--header-image {
      width: 100%;
      max-width: 100%;
      max-height: 150px;
      &.v-image.v-responsive {
        border-radius: 4px 4px 0 0;
      }
    }
    &.v-card:last-child {
      .content-card--card-bottom {
        border-radius: 0 0 4px 4px;
        border-top: none;
        border-left: 1px solid $primary-medium;
      }
    }

    .content-card--card-bottom-info-wrapper {
      .content-card--card-bottom-info {
        display: flex;
        flex-direction: column;
        .v-card__subtitle {
          padding: 0;
        }
      }
      .v-card__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > .v-btn.v-btn {
          margin-top: 10px;
          flex: 0 50%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
